.notification_notification_icon__d_Pme {
  width: 128px;
  height: 128px;
}

.notification_notification__5_lLg {
  min-width: 10vw;
  max-width: 20vw;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  height: -moz-fit-content;
  height: fit-content;
  pointer-events: auto;
  background-color: white;
  padding: 12px;
}

@media screen and (min-width: 356px) and (max-width: 1350px) {
  .notification_notification__5_lLg {
    max-width: 70vw;
  }
}

@media screen and (max-width: 356px) {
  .notification_notification__5_lLg {
    max-width: 90vw;
  }
}

.notification_message_container__KSF4C {
  margin-left: 12px;
  margin-right: 24px;
  width: 100%;
}

.notification_accept_button__2o5E3 {
  padding: 24px;
  cursor: pointer;
}

.notification_header__YqKni {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 8px;
}

.notification_header_container__1bwZy {
  display: flex;
  justify-content: space-between;
}

.notification_icon_button___yrFT {
  cursor: pointer;
}

.notification_provider_notifications_container__maQ13 {
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  right: 0;
  z-index: 1500;
  pointer-events: none;
  display: flex;
  flex-direction: row-reverse;
  padding: 25px;
  margin-top: 75px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
}

.typography_textSmallLight__pTW_V {
  font-family: var(--font-family-primary);
  font-weight: 300;
  font-size: 14px;
}

.typography_textSmall__K9doM {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 14px;
}

.typography_textSmallMedium__ly6IE {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 14px;
}

.typography_textSmallBold__SwytG {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 14px;
}

.typography_textSmallBlack__q7FVA {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 14px;
}

/* Large Text */

.typography_textLargeLight__ES9of {
  font-family: var(--font-family-primary);
  font-weight: 300;
  font-size: 18px;
}

.typography_textLarge__mi1El {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 18px;
}

.typography_textLargeMedium__Ejg75 {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 18px;
}

.typography_textLargeBold___j38Z {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 18px;
}

.typography_textLargeBlack__xorNa {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 18px;
}

/* Regular Text */

.typography_textLargeLight__ES9of {
  font-family: var(--font-family-primary);
  font-weight: 300;
  font-size: 16px;
}

.typography_textLarge__mi1El {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 16px;
}

.typography_textLargeMedium__Ejg75 {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 16px;
}

.typography_textLargeBold___j38Z {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 16px;
}

.typography_textLargeBlack__xorNa {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 16px;
}

.typography_textLogo__aOPNW {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.33%;
}

.typography_textTopSection__k03mk {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.33%;
  text-transform: uppercase;
}

.typography_textButton__csHjc {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.33%;
}

.typography_text22Bold__X60gg {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 22px;
}

.typography_text18Regular__d4fyA {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 18px;
}

.typography_text18Medium__RrAhr {
  font-family: var(--font-family-primary);
  font-weight: 600;
  font-size: 18px;
}

.typography_text14Medium__UR_Ia {
  font-family: var(--font-family-primary);
  font-weight: 600;
  font-size: 14px;
}

.typography_text32Semi__HTXNi {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 32px;
}

.typography_text32Regular__UWzbp {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 32px;
}

.typography_text14Regular__SaKsZ {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 14px;
}

.typography_text16Regular__MuDwl {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 16px;
}
.typography_text14Medium__UR_Ia {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 16px;
}

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap'); */
html,
body {
  padding: 0;
  padding-top: 38px;
  margin: 0;
  font-family: Montserrat;
  /* height: 100%; */
  overflow: overlay;
}

body > div {
  /* height: 100%; */
  /* max-height: 100vh; */
}

body > div > div {
  /* height: 100%; */
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

td {
  text-align: center;
}

.flex-end {
  display: flex;
  flex-direction: row-reverse;
}

.flex-row {
  display: flex;
  align-items: 'center';
  gap: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: transparent;
  border-radius: 100vh;
  position: absolute;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border: 1px solid var(--primary);
  opacity: 0.5;
  border-radius: 10px;
  position: absolute;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
  position: absolute;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#chartJS {
  image-rendering: optimizeSpeed; /* Older versions of FF */
  image-rendering: -moz-crisp-edges; /* FF 6.0+ */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non standard naming) */
  image-rendering: -o-crisp-edges; /* OS X & Windows Opera (12.02+) */
  image-rendering: crisp-edges; /* Possible future browsers. */
  -ms-interpolation-mode: nearest-neighbor; /* IE (non standard naming) */
}

:root {
  --primary: #d1273f;

  --sxm: 356px;
  --sm: 768px;
  --md: 1200px;
}

.icon-row{display:flex;flex-direction:row;align-items:center;gap:5px}.absolute{position:absolute}.fill-width{width:100%}.fill-height{height:100%}
.pin-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /* border-bottom-left-radius: 0; */
  background-color: var(--primary);
  transition: 0.1s all ease-in-out;
}

:root {
  --primary: #d1273f;
  --secondary: black;
  --text: #000c23;
  --text-transluent: black;
  --text-highlighted: black;
  --green: #4bb34b;
  --white: '#ffffff';

  --light-gray: #edeef0;

  --box-shadow-light: 0px 1px 4px rgba(0, 0, 0, 0.06);

  --spacing-medium: 1.5rem;
  --spacing-small: 12px;
  --font-family-primary: 'Roboto', sans-serif;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-size-xxs {
  font-size: xx-small;
}

.font-size-xs {
  font-size: x-small;
}

.font-size-s {
  font-size: small;
}

.font-size-m {
  font-size: medium;
}

.font-size-l {
  font-size: large;
}

.font-size-xl {
  font-size: x-large;
}

.font-size-xxl {
  font-size: xx-large;
}

.font-size-xxxl {
  font-size: xxx-large;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/df4ba022c23c08de-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b1464bad92c88a2d-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/8ed0c04f7e5d7b36-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c528baaebca50056-s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/1060bab20f18b5c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/df4ba022c23c08de-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b1464bad92c88a2d-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8ed0c04f7e5d7b36-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c528baaebca50056-s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1060bab20f18b5c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/df4ba022c23c08de-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/b1464bad92c88a2d-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8ed0c04f7e5d7b36-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c528baaebca50056-s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/1060bab20f18b5c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/df4ba022c23c08de-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b1464bad92c88a2d-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/8ed0c04f7e5d7b36-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c528baaebca50056-s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1060bab20f18b5c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df4ba022c23c08de-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b1464bad92c88a2d-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8ed0c04f7e5d7b36-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c528baaebca50056-s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1060bab20f18b5c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/df4ba022c23c08de-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/b1464bad92c88a2d-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/8ed0c04f7e5d7b36-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c528baaebca50056-s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/1060bab20f18b5c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/df4ba022c23c08de-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/b1464bad92c88a2d-s.p.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/8ed0c04f7e5d7b36-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/c528baaebca50056-s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_ca0877';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/1060bab20f18b5c2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_ca0877';src: local("Arial");ascent-override: 84.97%;descent-override: 22.03%;line-gap-override: 0.00%;size-adjust: 113.92%
}.__className_ca0877 {font-family: '__Montserrat_ca0877', '__Montserrat_Fallback_ca0877';font-style: normal
}

