/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap'); */
html,
body {
  padding: 0;
  padding-top: 38px;
  margin: 0;
  font-family: Montserrat;
  /* height: 100%; */
  overflow: overlay;
}

body > div {
  /* height: 100%; */
  /* max-height: 100vh; */
}

body > div > div {
  /* height: 100%; */
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

td {
  text-align: center;
}

.flex-end {
  display: flex;
  flex-direction: row-reverse;
}

.flex-row {
  display: flex;
  align-items: 'center';
  gap: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: transparent;
  border-radius: 100vh;
  position: absolute;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border: 1px solid var(--primary);
  opacity: 0.5;
  border-radius: 10px;
  position: absolute;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
  position: absolute;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#chartJS {
  image-rendering: optimizeSpeed; /* Older versions of FF */
  image-rendering: -moz-crisp-edges; /* FF 6.0+ */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non standard naming) */
  image-rendering: -o-crisp-edges; /* OS X & Windows Opera (12.02+) */
  image-rendering: crisp-edges; /* Possible future browsers. */
  -ms-interpolation-mode: nearest-neighbor; /* IE (non standard naming) */
}

:root {
  --primary: #d1273f;

  --sxm: 356px;
  --sm: 768px;
  --md: 1200px;
}
