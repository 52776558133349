.textSmallLight {
  font-family: var(--font-family-primary);
  font-weight: 300;
  font-size: 14px;
}

.textSmall {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 14px;
}

.textSmallMedium {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 14px;
}

.textSmallBold {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 14px;
}

.textSmallBlack {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 14px;
}

/* Large Text */

.textLargeLight {
  font-family: var(--font-family-primary);
  font-weight: 300;
  font-size: 18px;
}

.textLarge {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 18px;
}

.textLargeMedium {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 18px;
}

.textLargeBold {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 18px;
}

.textLargeBlack {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 18px;
}

/* Regular Text */

.textLargeLight {
  font-family: var(--font-family-primary);
  font-weight: 300;
  font-size: 16px;
}

.textLarge {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 16px;
}

.textLargeMedium {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 16px;
}

.textLargeBold {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 16px;
}

.textLargeBlack {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 16px;
}

.textLogo {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.33%;
}

.textTopSection {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.33%;
  text-transform: uppercase;
}

.textButton {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.33%;
}

.text22Bold {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 22px;
}

.text18Regular {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 18px;
}

.text18Medium {
  font-family: var(--font-family-primary);
  font-weight: 600;
  font-size: 18px;
}

.text14Medium {
  font-family: var(--font-family-primary);
  font-weight: 600;
  font-size: 14px;
}

.text32Semi {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 32px;
}

.text32Regular {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 32px;
}

.text14Regular {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 14px;
}

.text16Regular {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 16px;
}
.text14Medium {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 16px;
}
