.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-size-xxs {
  font-size: xx-small;
}

.font-size-xs {
  font-size: x-small;
}

.font-size-s {
  font-size: small;
}

.font-size-m {
  font-size: medium;
}

.font-size-l {
  font-size: large;
}

.font-size-xl {
  font-size: x-large;
}

.font-size-xxl {
  font-size: xx-large;
}

.font-size-xxxl {
  font-size: xxx-large;
}
