.icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.absolute {
  position: absolute;
}

.fill-width {
  width: 100%;
}

.fill-height {
  height: 100%;
}
