.notification_icon {
  width: 128px;
  height: 128px;
}

.notification {
  min-width: 10vw;
  max-width: 20vw;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  height: fit-content;
  pointer-events: auto;
  background-color: white;
  padding: 12px;
}

@media screen and (min-width: 356px) and (max-width: 1350px) {
  .notification {
    max-width: 70vw;
  }
}

@media screen and (max-width: 356px) {
  .notification {
    max-width: 90vw;
  }
}

.message_container {
  margin-left: 12px;
  margin-right: 24px;
  width: 100%;
}

.accept_button {
  padding: 24px;
  cursor: pointer;
}

.header {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 8px;
}

.header_container {
  display: flex;
  justify-content: space-between;
}

.icon_button {
  cursor: pointer;
}
