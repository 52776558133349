:root {
  --primary: #d1273f;
  --secondary: black;
  --text: #000c23;
  --text-transluent: black;
  --text-highlighted: black;
  --green: #4bb34b;
  --white: '#ffffff';

  --light-gray: #edeef0;

  --box-shadow-light: 0px 1px 4px rgba(0, 0, 0, 0.06);

  --spacing-medium: 1.5rem;
  --spacing-small: 12px;
  --font-family-primary: 'Roboto', sans-serif;
}
