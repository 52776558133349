.notifications_container {
  position: absolute;
  width: fit-content;
  height: 100%;
  right: 0;
  z-index: 1500;
  pointer-events: none;
  display: flex;
  flex-direction: row-reverse;
  padding: 25px;
  margin-top: 75px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
}
